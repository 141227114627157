<template>
  <div>
    <div class="portal-hero-section text-white text-center d-flex align-items-center flex-column ">
      <div class="d-flex flex-column text-center align-items-center">
        <b-img class="mb-3" :src="require('@/assets/images/logo/tingedu-logo-white-horizontal.png')"
          style="max-width: 200px; max-height: 60%"></b-img>
        <span class="mb-20 font-weight-bolder font-large-1">CỔNG THU PHÍ TỰ ĐỘNG</span>
        <span class="mb-1 font-weight-bolder font-medium-1">DÀNH CHO LĨNH VỰC GIÁO DỤC & ĐÀO TẠO</span>
        <b-input-group class="mt-1">
          <!-- <b-input-group-prepend>
          <b-button variant="outline-primary">
            <feather-icon icon="SearchIcon" />
          </b-button>
        </b-input-group-prepend> -->
          <b-form-input placeholder="Nhập số điện thoại để tra cứu hóa đơn" />
          <b-input-group-append>
            <b-button variant="warning">
              Tra cứu
            </b-button>
          </b-input-group-append>
        </b-input-group>

      </div>

    </div>
    <section class="section-footer">
      <p class="clearfix mb-0">
        <span class="float-md-left d-block d-md-inline-block mt-25">
          © {{ new Date().getFullYear() }}
          <b-link class="ml-25" href="https://tingee.vn" target="_blank">TingEdu</b-link>
        </span>

        <b-button class="float-md-right d-none d-md-block" variant="outline-danger" href="tel://02471088968" size="sm">
          <feather-icon icon="PhoneIcon" class="mr-50" />
          <span class="align-middle">0247 1088 968</span>
        </b-button>
        <b-button class="float-md-right d-none d-md-block mr-50" variant="outline-success"
          href="https://docs-tingedu.tingee.vn/" target="_blank" size="sm">
          <feather-icon icon="BookOpenIcon" class="mr-50" />
          <span class="align-middle">Hướng dẫn sử dụng</span>
        </b-button>
      </p>
    </section>
  </div>

</template>


<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import {
  BButton, BForm, BFormInput, BFormGroup, BCard, BCardTitle, BCardText, BInputGroup, BInputGroupPrepend, BInputGroupAppend, BRow, BCol, BImg, BLink
} from 'bootstrap-vue';
import { required } from '@validations';
import Brand from '@/layouts/components/Brand.vue';

export default {
  components: {
    // BSV
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BCardTitle,
    Brand,
    BCardText,
    ValidationProvider,
    ValidationObserver, BInputGroup, BInputGroupPrepend, BInputGroupAppend, BRow, BCol, BImg,
    BLink
  },
  data() {
    return {
      // validation rules
      required,
    };
  },

  setup() {

  },

};
</script>

<style lang="scss" scoped>
.portal-hero-section {
  background-image: url('../../../assets/images/pages/hero-bg.jpg');
  background-size: contain;
  // background-position: center;
  // background-repeat: no-repeat;
  width: 100%;
  // height: 300px;
  padding-top: 68px;
  padding-bottom: 100px;
}
</style>